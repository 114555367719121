<template>
  <v-app>
    <v-navigation-drawer
        v-if="isLoggedIn"
        v-model="showDrawer"
        :value="true"
        clipped
        app
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar @click="editAvatarImage">
            <img :src="user.avatarImage ?  getFileUrl(user.avatarImage.path) : '/dummy-profile.png'" alt="Profile image">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user && user.firstName + " " + user.lastName  }}</v-list-item-title>
            <v-list-item-subtitle>Angemeldet</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item
            v-if="clusters && hasPermission(['admin'])"
            to="/admin/clusters/"
            link
        >
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Clusters</v-list-item-title>
        </v-list-item>
        <v-list-group v-if="clusters.length > 0" prepend-icon="mdi-account-group" :value="true">
          <template v-slot:activator>
            <v-list-item-title>Clusters</v-list-item-title>
          </template>
          <v-list-group no-action sub-group v-for="cluster in clusters" :key="cluster.id" >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{cluster.name}}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="`/admin/clusters/${cluster.id}/members`" link>
              <v-list-item-icon>
                <v-icon>mdi-note-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Mitglieder</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/admin/clusters/${cluster.id}/categories`" link>
              <v-list-item-icon>
                <v-icon>mdi-file-tree</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Kategorien</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/admin/clusters/${cluster.id}/tags`" link>
              <v-list-item-icon>
                <v-icon>mdi-tag-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Tags</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/admin/clusters/${cluster.id}/joinRequests`" link>
              <v-list-item-icon>
                <v-icon>mdi-bullhorn-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Mitgliedsanfragen</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-group>

        <template v-for="menuEntry in userMenuEntries">
          <v-list-item v-if="!menuEntry.children" :key="menuEntry.link" :to="menuEntry.link">
            <v-list-item-icon>
              <v-icon v-text="menuEntry.icon"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="menuEntry.title" />
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              v-else
              :key="menuEntry.title"
              v-model="menuEntry.active"
              :prepend-icon="menuEntry.icon"
              no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="menuEntry.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="child in menuEntry.children"
                :key="child.title"
                :to="child.link"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
        <v-divider/>
        <v-list-item @click="doLogout">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        clipped-left
        app
        color="primary"
        dark
        dense
    >
      <div class="d-flex align-center">
        <v-app-bar-nav-icon class="d-md-none" @click="showDrawer=!showDrawer"></v-app-bar-nav-icon>
        Expertensuche Admin
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main class="px-1">
      <v-progress-linear indeterminate :active="true" :color="progressActive ? 'red' : 'white'"></v-progress-linear>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import APIService from "@/services/APIService";
import store from "@/store";
import {getFileUrl} from "@/utils/common";

export default {
  name: 'AdminLayout',

  components: {
  },
  data () {
    return {
      showDrawer: false,
      clusters: [],
      progressActive: false,
      menuEntries: [
        {
          title: 'Benutzer',
          icon: 'mdi-account-multiple',
          link: '/admin/users',
          active: true,
          auth: ['admin']
        },
        {
          title: 'Posts',
          icon: 'mdi-note-outline',
          link: '/admin/posts',
          active: true,
          auth: ['user', 'manager']
        },
        {
          title: 'Veranstaltungen',
          icon: 'mdi-calendar-month',
          link: '/admin/events',
          active: true,
          auth: ['user', 'manager']
        },
        {
          title: 'Firmendaten',
          icon: 'mdi-office-building-cog-outline',
          link: '/admin/profile',
          active: true,
          auth: ['user', 'manager']
        },
        {
          title: 'Benutzer-Profil',
          icon: 'mdi-account-settings',
          link: '/admin/userProfile',
          active: true,
          auth: ['user', 'manager']
        },
      ]
    }
  },
  methods: {
    getFileUrl,
    async doLogout() {
      await this.$store.dispatch('logout')
      await this.$router.push('/')
    },
    hasPermission(auth = []) {
      return auth.includes(this.user.role)
    },
    editAvatarImage(){
      console.log("Edit avatar image")
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapState(['user']),
    userMenuEntries() {
      return this.menuEntries.filter(entry => entry.auth && entry.auth.includes(this.user.role))
    },
  },
  mounted: function () {
    if(this?.user?.id) {
      APIService.getUserClusters(this.user.id).then(clusters => {
        this.clusters = clusters
      })
    }
    this.showDrawer = this.$vuetify.breakpoint.lgAndUp
  },
  beforeRouteEnter: function(to, from, next) {
    if(!store.getters.isLoggedIn) {
      next('/login')
    }
    next()
  }
}


</script>
